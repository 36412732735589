.PhotoView-SlideWrap {
  z-index: 900;
}
.warning {
  display: flex;
  align-items: center;
}
.warning span {
  margin: 0 16px;
}
.PhotoView__PhotoBox {
  width: inherit;
  height: inherit;
}
.PhotoView-SlideWrap .PhotoHasSideCover {
  width: 70%;
  max-width: calc(100% - 200px);
  min-width: calc(100% - 410px);
}
.PhotoHasOverlayCover {
  height: calc(100vh - 128px);
}
.PhotoView-SlideWrap .PhotoSliderArrowRightNormal {
  right: 15vw;
}
.PhotoView-SlideWrap .PhotoSliderArrowLeftNormal {
  left: 15vw;
}
.PhotoView-SlideWrap .PhotoSliderArrowRight {
  left: 30%;
}
