.ue-excel-box .ant-table-wrapper {
  width: 576px !important;
}
.ue-excel-box .ant-table-wrapper .ant-table-cell {
  padding: 6px 12px;
}
.ue-excel-box .up-once {
  margin-left: -15px;
}
.ue-excel-box .ue-crad-com {
  margin-bottom: 24px;
}
.ue-excel-box .ue-crad-com .ant-card-body {
  padding: 0;
}
.ue-excel-box .ue-crad-com .ant-typography {
  color: #1f2329 !important;
}
.ue-excel-box .ue-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ue-excel-box .ue-decs {
  font-size: 12px;
  padding: 6px 0 0;
  font-weight: normal;
  color: #646a73;
  margin-bottom: 0;
}
.ue-excel-box .ue-decs span {
  color: #ff371c;
}
.ue-excel-box .ue-crad-img {
  border-radius: 2px;
  padding: 16px 22px;
}
.ue-excel-box .ue-crad-img img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
}
.ue-excel-box .ue-download-box {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.ue-excel-box .ue-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ue-excel-box .ue-table-show {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f7fb;
  border-radius: 2px;
  padding: 16px 22px;
  min-height: 138px;
  box-sizing: border-box;
}
.ue-excel-box .ue-table-show img {
  width: 60px;
  height: 60px;
}
.ue-excel-box .ant-table-cell {
  padding: 0;
  min-height: 35px;
}
.ue-excel-box .ant-table-cell .table-cell-content {
  width: 100%;
  min-height: 35px;
  padding: 6px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ue-excel-box .ant-table-cell .err {
  background: #ff371c;
  color: white;
}
.ue-excel-box .ant-upload {
  width: auto !important;
  height: 32px !important;
}
.ue-excel-box .ue-repeat-box {
  margin-bottom: 24px;
  margin-top: -10px;
}
.ue-excel-box .ue-repeat-box .ue-repeat-title {
  display: block;
  color: #ff371c;
  font-size: 14px;
  margin-bottom: 2px;
}
.ub-error-item {
  color: #ff371c !important;
}
.nameTxt .ant-popover-inner-content {
  max-width: 250px;
  white-space: pre-line;
  word-break: break-all;
}
